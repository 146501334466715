@font-face {
  font-family: 'Highman';
  src: local('Highman'), url(../../fonts/Highman.otf);
}

@font-face {
  font-family: 'Akira';
  src: local('Akira'), url(../../fonts/Akira_Expanded.otf);
}

.form-container {
  background: linear-gradient(to right, #c1ff3d, #ff6600);
  margin: 0 auto;
  border-radius: 45px;
  max-width: 1000px;
  padding-left: 3px;
  padding-right: 3px;
}

.form {
  font-family: Arial, sans-serif;
  max-width: 1000px;
  border: 2px solid #0000005d;
  background-color: black;
  border-radius: 45px;
  margin: 0 auto;
}

.thank-you-container {
  margin: 0 auto;
  background: linear-gradient(to right, #c1ff3d, #ff6600);
  border-radius: 45px;
  padding-left: 3px;
  padding-right: 3px;
}

.thank-you-box {
  background-color: black;
  color: #fff;
  border-radius: 45px;
  padding: 20px;
}

label {
  display: block;
  font-family: 'Highman', sans-serif;
  color: #fff;
  font-size: min(50px, 10vw);
}

.lead-gen-title {
  font-family: 'Akira', sans-serif;
  color: white;
  font-size: min(50px, 5vw);
}

input[type="text"],
input[type="email"],
input[type="tel"],
select {
  width: 100%;
  padding: 12px;
  margin: 15px 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border-radius: 8px;
  transition: all 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
select:focus {
  border-color: #ffffff;
  box-shadow: 0 0 5px rgba(193, 255, 61, 0.5);
}
  
.lead-gen-button {
  font-family: 'Highman', sans-serif;
  border-radius: 20px;
  border: 2px solid white;
  color: white;
  font-size: calc(12px + 2.5vmin);
  font-weight: bold;
  letter-spacing: 6.5px;
  transition: transform 0.2s;
  box-shadow: 0px 18px 18px 0px rgba(0, 0, 0, 0.25);
  background-color: #030303;
  margin: 15px;
  transition: ease-in-out 0.1s;
}

.lead-gen-button:hover {
  transform: scale(1.05);
}

.lead-gen-button:focus {
  outline: none;
}

.lead-gen-button.selected {
  border: 2px solid;
  border-color: #c1ff3d;
  transform: scale(1.05);
  background-color: rgb(17, 17, 17);
}

/* Style for the neighborhood selection checkboxes */
.lead-gen-neighborhood-selection-checkbox {
  /* Optional: Remove default browser styles */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: min(65px, 16vmin); /* Adjust this to your preferred checkbox size */
  height: min(65px, 16vmin); /* Adjust this to your preferred checkbox size */
  background-color: #fff; /* Background color when unchecked */
  border: 4px solid #c1ff3d; /* Border color for the checkbox icon */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
  transition: ease-in-out 0.05s;

}

/* Style for the custom checkbox checkmark */
.lead-gen-neighborhood-selection-checkbox:checked {
  background-color: #ff9d47;
  border-color: white; 
  transform: scale(1.15);
}

.lead-gen-neighborhood-checkbox-text {
  color: white;
  font-size: calc(18px + 3.5vmin);
  font-weight: bold;
  letter-spacing: 2.5px;
}

.lead-gen-investing-interest-button {
  font-family: 'Highman', sans-serif;
  border-radius: 25px;
  border: 2px solid #C1FF3D;
  transition: transform 0.2s;
  box-shadow: 0px 18px 18px 0px rgba(0, 0, 0, 0.25);
  background-color: #030303;
  max-width: 100%;
  width: 100%;
  height: 150px;
}

.lead-gen-investing-interest-button:hover {
  border-color: #ff7700;
  transform: scale(1.025);
}

.lead-gen-investing-interest-text {
  color: white;
  font-size: calc(18px + 2.5vmin);
  font-weight: bold;
  letter-spacing: 2.5px;
}

.lead-gen-next-button {
  font-family: 'Highman', sans-serif;
  border-radius: 25px;
  border: 4px solid white;
  transition: transform 0.1s;
  box-shadow: 0px 18px 18px 0px rgba(0, 0, 0, 0.25);
  background-color: #030303;
  width: min(45vmin, 200px);
  height: 120px;
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 3.5px;
}

.lead-gen-next-button:hover {
  border-color: #ffffff;
  transform: scale(1.025);
}

.lead-gen-next-button:focus {
  outline: none;
}

.scheduling-appointment-button {
  font-family: 'Highman', sans-serif;
  border-radius: 25px;
  border: 2px solid #C1FF3D;
  transition: transform 0.1s;
  box-shadow: 0px 18px 18px 0px rgba(0, 0, 0, 0.25);
  background-color: #030303;
  width: min(45vmin, 250px);
  height: 120px;
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 3.5px;
}

select {
  background-color: #000;
  color: #fff;
  font-size: min(30px, 5vw);
}

.progress-bar {
  height: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  margin: 20px 0;
}

.progress {
  height: 100%;
  background: linear-gradient(to right, #c1ff3d, #ff6600);
  border-radius: 15px;
  width: 0;
}

.contact-details-page-divider {
  width: 80%;
  height: 2px;
  background: #333;
  margin: 15px auto;
  margin-top: 15px;
}

