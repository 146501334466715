.trusted-network-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: #010101;
}

.trusted-network-title {
  font-family: 'Akira'; 
  font-size: min(10vw, 50px);
  text-align: left;
  width: 100%;
  color: white;
}