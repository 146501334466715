.dropdown-container {
    background-color: #000000bb;
    border: 2px solid rgb(38, 38, 38);
    border-radius: 25px;
    transition: transform 0.2s ease-in-out;
    z-index: 3;
    position: absolute;
}

.dropdown-content div {
    color: white;
    letter-spacing: .2vw;
    font-size: calc((2vw + 3vh)/2);
    width: calc((16vw + 9vh)/1.5 + 1em);
    animation: dropdown 0.2s ease forwards;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    display: block;
    opacity: 0;
}

.dropdown .dropbtn {
    letter-spacing: .2vw;
    font-size: calc((1.5vw + 1.5vh)/1.5 + 1.5em);
    color: white;
    border: none;
    cursor: pointer;
    transition: color 0s, transform 0.2s ease-in-out;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    outline: none;
}

.dropdown-content div:hover {
    -webkit-text-fill-color: transparent; 
    background-image: linear-gradient(45deg, #c1ff3d, #FF6600);
    transform: scale(1.05);
}

.dropdown:hover .dropbtn {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(45deg, #c1ff3d, #FF6600);
    transform: scale(1.05);
}

.dropdown:hover .dropdown-content div {
    animation: dropdown 0.7s ease forwards;
}

.dropdown:hover .dropdown-content div:nth-child(1) {animation-delay: 0s;}
.dropdown:hover .dropdown-content div:nth-child(2) {animation-delay: 0.05s;}
.dropdown:hover .dropdown-content div:nth-child(3) {animation-delay: 0.1s;}
.dropdown:hover .dropdown-content div:nth-child(4) {animation-delay: 0.15s;}
.dropdown:hover .dropdown-content div:nth-child(5) {animation-delay: 0.20s;}
.dropdown:hover .dropdown-content div:nth-child(6) {animation-delay: 0.25s;}
.dropdown:hover .dropdown-content div:nth-child(7) {animation-delay: 0.30s;}
.dropdown:hover .dropdown-content div:nth-child(8) {animation-delay: 0.35s;}
.dropdown:hover .dropdown-content div:nth-child(9) {animation-delay: 0.40s;}
.dropdown:hover .dropdown-content div:nth-child(10) {animation-delay: 0.45s;}
.dropdown:hover .dropdown-content div:nth-child(11) {animation-delay: 0.50s;}
.dropdown:hover .dropdown-content div:nth-child(12) {animation-delay: 0.55s;}

@keyframes dropdown {
    0% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .dropdown-container {
        width: 65vw;
    }

    .dropdown-content div {
        font-size: 2rem;
        width: 100%;
    }

    .dropdown .dropbtn {
        font-size: 1.5rem;
    }
}

@media (max-width: 450px) {
    .dropdown-container {
        width: 45vw;
    }

    .dropdown-content div {
        font-size: 1.25rem;
        width: 100%;
    }

    .dropdown .dropbtn {
        font-size: 1.25rem;
    }
}