/* HeroSection.css */
@font-face {
  font-family: 'Highman';
  src: local('Highman'), url(../../../fonts/Highman.otf);
}

@keyframes blueprintAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes groupShotAppear {
  0% {
    transform: translateY(1%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes textAnimation {
  0% {
    opacity: 0;
    transform: translateX(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-section-container {
  position: relative;
  height: calc((80vh + 40vw)/2);
  max-height: 100vh;
  min-height: 540px;
  width: 100vw;
  overflow: hidden;
}

.background-images {
  z-index: -1;
}

.blueprint-background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: blueprintAppear 3.5s ease-out;
}

.mountains-background {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  height: 120%; 
  object-fit: cover;
}

.hero-section-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.group-shot-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.group-shot-image {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.hero-section-text-container {
  position: relative;
  left: 40%;
  top: 50vh;
  z-index: 2;
}

.hero-section-text {
  position: absolute;
  text-align: left;
  font-family: 'Highman', Arial, sans-serif;
  color: white;
  z-index: 1;
  animation: textAnimation 1.5s 0.5s ease-in-out forwards;
  opacity: 0;
}

.hero-section-title {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  line-height: .8;
  font-size: calc(5vw + 2.5em);
  margin: 0;
}

.hero-section-title span {
  color: rgb(252, 61, 61);
  -webkit-text-stroke: 3.5px black;
  font-size: calc(1vw + .5em);
}

.hero-section-title strong {
  color: white;
  -webkit-text-stroke: 0.5px black;
}

.hero-section-title em {
  color: black;
  -webkit-text-stroke: 3.5px #c1ff3d;
} 


@media screen and (max-width: 768px) {
  .hero-section-container {
    height: 60vh;
    min-height: 300px;
  }

  .group-shot-image {
    width: calc((100vw + 100vh) / 2);
  }

  .hero-section-text-container {
    top: 35vh;
    left: 15vw;
  }

  .group-shot-container {
  }

  .hero-section-content {
  }
}

/*
.hero-section-content-wrapper {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}


.group-shot-container {
}


.hero-section-content {
  position: absolute;
  width: auto;
  height: auto;
  top: 45vh;
  left: 40vw;
}

.group-shot-image {
  animation: groupShotAppear 2.5s ease-out forwards;
}
/*
@media screen and (max-width: 1400px) {
  .master-container {
    height: calc((40vh + 55vw)/1.5);
  }

  .group-shot-image {
    width: calc((100vw + 100vh) / 2);
  }

  .group-shot-container {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .hero-section-content {
    left: 0;
    top: calc((25vh + 15vw)/2);
  }
} */


/* This was css for Canva mockup Dan scraped */
/* @font-face {
  font-family: 'Allura';
  src: local('Allura'), url(../../../fonts/Allura-Regular.ttf);
}

@font-face {
  font-family: 'Alsscrp';
  src: local('Alsscrp'), url(../../../fonts/alsscrp.ttf);
}

.hero-section-wrapper {
  width: 100vw;
  margin: 0px auto;
}

.hero-section-container {
  background-image: url('../../../images/dan_rachael_bw_bell_2.jpg');
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  width: min(100vw, 1920px);
  height: 80vh;
  min-height: 1080px;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .hero-section-container {
    background-size: cover;
    background-size: auto 50vw;
    height: max(50vh, 400px);
    min-height: 350px;
  }
}

.hero-section-signatures {
  font-size: calc((8vw + 3vh)/2);
}

.dan-signature {
  font-family: Allura, sans-serif;
  transform: rotate(-2deg);
}

.rachael-signature {
  font-family: Alsscrp, sans-serif;
  transform: rotate(-5  deg);
} */