@font-face {
    font-family: 'Highman';
    src: local('Highman'), url(../../../fonts/Highman.otf);
  }

  @font-face {
    font-family: 'Akira';
    src: local('Akira'), url(../../../fonts/Akira_Expanded.otf);
  }
  
  .episode-carousel-wrapper {
    position: relative;
    max-width: clamp(0px, 80%, 1310px);
    margin: 0px auto;    
  }
  
  .featured-episodes-container {
    width: clamp(0px, 100%, 1400px);
    margin: 0px auto;
    color: white;
  }

  .episode-carousel-button-next,
  .episode-carousel-button-prev {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    height: 100%;
  }
  
  .episode-carousel-button-next::after,
  .episode-carousel-button-prev::after {
    content: '';
    display: inline-block;
    border: solid #fff;
    border-width: 0 8px 8px 0;
    padding: 70px;
  }
  
  .episode-carousel-button-next::after {
    transform: rotate(-225deg);
  }
  
  .episode-carousel-button-prev::after {
    transform: rotate(-45deg);
  }
  
  .episode-carousel-button-prev {
    right: 20px;
  }
  
  .episode-carousel-button-next {
    left: 20px;
  }
  
  .episode-carousel-image {
    width: 100%;
    display: block;
  }
  
  .episode-lets-connect {
    font-family: Highman, san-serif;
    color: #c1ff3d;
    font-size: 3.25rem;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }

  .episode-lets-connect:hover {
    transform: scale(1.1);
  }
  
  .featured-episodes-title-text {
    margin: 0px auto;
    width: 100%;
  }

  .featured-episodes-section-title,
  .featured-episodes-section-subtitle {
    font-family: Akira, san-serif;
    color: white;
  }

  .featured-episodes-section-title {
    font-size: 3rem;
  }

  .featured-episodes-section-subtitle {
    font-size: 1.25rem;
    color: #c1ff3d;
  }

  .episode-broker-name {
    font-family: Akira, san-serif;
    font-size: 30px;
    color: #ffb686df;

  }

  .episode-broker-intro-text {
    font-family: Highman, san-serif;
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    .episode-carousel-wrapper {
      max-width: 100%;
    }

    .episode-lets-connect {
      font-size: 2rem;
    }
    
    .featured-episodes-section-title {
      font-size: 1.5rem;
    }
    
    .featured-episodes-section-subtitle {
      font-size: 1rem;
    }
    
    .episode-broker-name {
      font-size: 30px;
    }

    .episode-carousel-button-next::after,
    .episode-carousel-button-prev::after {
      padding: 10px;
    }
  }


  @media (max-width: 408px) {
    .episode-lets-connect {
      font-size: 1.25rem;
    }
    
    .featured-episodes-section-title {
      font-size: 1.5rem;
    }
    
    .featured-episodes-section-subtitle {
      font-size: 1rem;
    }
    
    .episode-broker-name {
      font-size: 2rem;
    }

    .episode-carousel-button-next::after,
    .episode-carousel-button-prev::after {
      padding: 5px;
    }
  }