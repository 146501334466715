/* Banner.css */
.banner {
    position: absolute;
    width: 140%;
    top: 31%;
    transform: rotate(-5deg);
    transform-origin: center;
  }
  
  .banner-text {
    font-family: 'Highman';
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.75);
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    font-size: 3vmin;
    white-space: nowrap;
    width: 100%;
    text-align: center;
  }
  
  .scroll-banner {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.25s;
  }
  
  .scroll-banner-text {
    font-family: 'Highman';
    letter-spacing: 6px;
    font-size: 1.5vmin; 
    white-space: nowrap;
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, .15); 
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  }
  
  @media screen and (max-width: 768px) {
    .banner-text  {
      font-size: 3.5vmin;
    }

    .banner {
      top: 45%;
    }
  }