@font-face {
    font-family: 'Highman';
    src: local('Highman'), url(../../../fonts/Highman.otf);
  }
  
  .sponsors-container {
    background-color: rgb(5, 5, 5);
    border-radius: 15px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 15px;
  }

  .sponsors-grid {
    margin: 0 auto;
    padding: 2rem;
    padding-top: 0;
  }
  
  .sponsors-title {
    font-family: 'Highman', sans-serif;
    font-size: min(14vw, 175px);
    background: linear-gradient(45deg, #c1ff3d, #FF6600);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding-top: 4rem;
  }
  
  .sponsors-sponsor-box {
    background-color: black;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    transition: transform 0.15s ease-in-out;
    margin: .5rem;
  }
  
  .sponsors-sponsorbox-inner {
    width: 100%;
    height: auto;
    object-fit: contain; /* Keeps aspect ratio of the image */
    max-width: 75%;
    max-height: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .sponsors-page-divider {
    width: 80%;
    height: 2px;
    background: #333;
    margin: 40px auto;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    .sponsors-title {
      font-size: 22vw;;
    }
  }