/* SocialMediaIcons.css */

.social-icons {
  margin: 0px auto;
  z-index: 3;
}
  
.icon-button {
  color: #ffffff;
  font-size: calc((4vw + 4vh) / 2);
  transition: transform 0.3s ease;
}

.icon-button:hover {
  transform: scale(1.2);
  color: white;
}
