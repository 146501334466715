:root {
  --white: #ffffff;
  --video-width: min(430px, calc(0.7 * 100vw));
  --video-height: auto;
  --video-min-width: auto;
  --video-min-height: auto;
  --pagination-bullet-size: 1.4rem;
  --swiper-container-width: 100vw;
}

.carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  min-height: 800px;
}

.swiper_container {
  max-width: var(--swiper-container-width);
}

.swiper-slide {
  display: flex;
  justify-content: center;
  width: var(--video-width);
  height: var(--video-height);
  position: relative;
}

.swiper-slide video {
  width: var(--video-width);
  height: var(--video-height);
  min-width: var(--video-min-width);
  min-height: var(--video-min-height);
  border-radius: 4rem;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  width: 50px;
  height: 50px;
  background: #ff66000f; /* Setting the background color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 6px solid #c1ff3d; /* Adding the border with 10px width and specified color */
  backdrop-filter: blur(5px); /* Applying a blur effect to the backdrop */
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none; /* This removes the arrow inside the button */
}

.swiper-button-next {
  right: calc((var(--swiper-container-width)) / 3);
}

.swiper-button-prev {
  left: calc((var(--swiper-container-width)) / 3);
}

.swiper-pagination {
  margin-top: 25px;
  position: relative;
}

.swiper-pagination .swiper-pagination-bullet {
  width: var(--pagination-bullet-size);
  height: var(--pagination-bullet-size);
  background: #cccccc;
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #c1ff3ddd;
}

@media (max-width: 1850px) {
  .swiper-button-next {
    right: calc(0.32 * 100vw);
  }
  
  .swiper-button-prev {
    left: calc(0.32 * 100vw);
  }
}

@media (max-width: 1450px) {

  .swiper-button-next {
    right: calc(0.30 * 100vw);
  }
  
  .swiper-button-prev {
    left: calc(0.30 * 100vw);
  }
}

@media (max-width: 1350px) {
  :root{
    --pagination-bullet-size: 1.6rem
  }

  .swiper-button-next {
    right: calc(0.20 * 100vw);
  }
  
  .swiper-button-prev {
    left: calc(0.20 * 100vw);
  }
}

@media (max-width: 1000px) {
  :root{
    --pagination-bullet-size: 1.8rem
  }
  .swiper-button-next {
    right: calc(0.10 * 100vw);
  }
  
  .swiper-button-prev {
    left: calc(0.10 * 100vw);
  }
}

@media (max-width: 700px) {
  :root{
    --pagination-bullet-size: 2.2rem
  }
  .swiper-button-next {
    right: calc(0.05 * 100vw);
  }
  
  .swiper-button-prev {
    left: calc(0.05 * 100vw);
  }
}

@media (max-width: 666px) {
  :root{
    --pagination-bullet-size: 2.4rem
  }

  :root {
    --video-width: calc(0.75 * 100vw);
    --video-height: auto;
  }
}
