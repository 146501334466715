@font-face {
  font-family: 'Highman';
  src: local('Highman'), url(../../../fonts/Highman.otf);
}

@font-face {
  font-family: 'Akira';
  src: local('Akira'), url(../../../fonts/Akira_Expanded.otf);
}

.welcome-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
}

.welcome-card {
  border-radius: 45px;
  border: 2px solid #5E5E5E;
  padding: 3%;
}

.welcome-title {
  font-family: 'Akira', sans-serif;
  text-shadow: 0px 15px 15px rgba(0, 0, 0, 0.25);
  font-size: min(10vw, 70px);
  margin-bottom: 10px;
  letter-spacing: 6px;
  font-weight: 800;
  color: #C1FF3D;
  text-align: center;
}

.welcome-profile-pic {
  border-radius: 25px;
  border: 2px solid #F60;
  width: 100%;
}

.social-icons-bar {
  color: white;
  border: 2px solid #F60;
  border-radius: 25px;
  height: 80px;
  width: 250px;
  top: -40px;
  position: relative;
  background-color: rgb(5, 5, 5);
  margin-left: auto;
  margin-right: auto;
}

.social-bar-icon-container {
  display: flex;
  align-items: center;
}

.social-bar-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.2s;
}

.social-bar-icon:hover {
  transform: scale(1.1);
}

.welcome-episode-box {
  border: 2px solid #F60;
  background-color: rgba(96, 96, 96, 0.10);
  padding: 15px;
  border-radius: 25px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  transition: ease-in-out 0.1s;
}

a:hover .welcome-episode-box {
  border-color: white;
  transform: scale(1.025);
  background-color: rgb(17, 17, 17);
}

.welcome-episode-text {
  font-size: min(45px, 4.5vmin);
  font-family: 'Highman', sans-serif;
}

.episode-link-text {
  font-size: min(20px, 1.5vmin);
  letter-spacing: 1px;
  font-family: 'Akira', sans-serif;
}

.welcome-youtube-link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.welcome-youtube-link:hover {
  color: white;
}

.youtube-icon {
  vertical-align: left;
  margin-right: 10px;
  color: red;
  width: 60px;
  height: auto;
}

.welcome-btn {
  font-family: 'Highman', sans-serif;
  border-radius: 25px;
  border: 2px solid #C1FF3D;
  color: white;
  font-size: calc(22px + 2.5vmin);
  font-weight: bold;
  letter-spacing: 3px;
  transition: transform 0.2s;
  width: 100%;
  margin: 8px 0;
  box-shadow: 0px 18.5px 18.5px 0px rgba(0, 0, 0, 0.25);
  transition: ease-in-out 0.1s;

}

.welcome-btn:focus {
  outline: 2px solid #F60;
}

.welcome-btn:hover {
  transform: translateY(-5px);
  border: 2px solid #F60;
}

.welcome-question-text {
  font-family: 'Akira', sans-serif;
  text-align: center;
  font-size: 1.4rem;
  color: white;
  letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
  .youtube-icon {
    width: 30vw;
    height: auto;
  }

  .welcome-episode-text {
    font-size: min(50px, 6vmin);
    font-family: 'Highman', sans-serif;
  }
  
  .episode-link-text {
    font-size: min(25px, 3.5vmin);
  }
}

