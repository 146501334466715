.progress-bar {
    height: 15px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    overflow: hidden;
    margin: 20px 0;
    position: relative;
  }
  
  .progress {
    height: 100%;
    background: linear-gradient(to right, #c1ff3d, #ff6600);
    border-radius: 15px;
    width: 0; /* Initial state */
    transition: width 0.5s ease; /* Smooth transition effect */
    position: relative;
  }
  
  .progress-text {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
    color: #000;
    z-index: 1; /* To ensure the text is above the animated liquid */
  }
  