.partner-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 15px;
    background-color: #191919;;
  }
  
  .partner-image-container {
    height: 470px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;
  }

  .partner-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    height: 500px;
    aspect-ratio: 1;
  }
  
  .partner-info {
    text-align: center;
    max-height: 100px;
  }
  
  .partner-name {
    color: #FFA264;
    font-family: Adamina;
    font-size: 37px;
    font-style: normal;
    line-height: normal;
    margin-bottom: 16px;
  }
  
  .partner-location {
    font-size: 22px;
    color: white;
    font-family: Adamina;
  }
  
  .partner-office {
    font-size: 25px;
    color: #C1FF3D;
    font-family: Adamina;
    margin-bottom: 6px;
  }
  