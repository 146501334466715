@font-face {
  font-family: 'Highman';
  src: local('Highman'), url(../../../fonts/Highman.otf);
}

@font-face {
  font-family: 'Akira';
  src: local('Akira'), url(../../../fonts/Akira_Expanded.otf);
}

.contact-box {
  background-color: black;
  border-radius: 15px;
}

.contact-me-container {
  background: linear-gradient(to right, #c1ff3d, #ff6600);
  border-radius: 15px;
  max-width: 1200px;
  width: 100%;
  background-color: #010101;
  color: white;
}

.contact-me-title {
  font-family: 'Akira';
  font-size: 70px;
  letter-spacing: 1px;
}

.left-column, .right-column {
  max-width: 400px;
  font-size: 30px;
}

.left-column svg, .right-column svg {
  color: white;
  margin: 30px;
}

.left-column a, .right-column a {
  color: white;
  font-size: 40px;
}

.post-container {
  width: 550px;
  height: 650px;
  overflow: hidden;
  border-radius: 15px;
}

.instagram-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 770px) {
  .post-container {
    width: 350px;
    height: 400px;
  }
  .contact-me-title {
    font-size: 50px;
  }
}

@media (max-width: 500px) {
  .post-container {
    width: 300px;
    height: 350px;
  }

  .contact-me-title {
    font-size: 40px;
  }

  .right-column {
    font-size: 25px;
  }
}